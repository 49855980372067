<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card-default animated-fast fadeInUp">
                        <div class="card-body p-0">
                            <div class="row p-5">
                                <div class="col-md-6">
                                    <img src="img/logo/cannergrow-full.svg" alt="company_logo"/>
                                </div>

                                <div class="col-md-6 text-right">
                                    <p class="font-weight-bold mb-1">{{$t('wallet.view.action.make_withdraw.item.heading')}} {{ withdraw.transaction.label }}</p>
                                    <p class="text-muted">{{$t('wallet.view.action.make_withdraw.item.created')}} {{ withdraw.created_at | moment('MMMM Do YYYY, h:mm:ss a') }}</p>

                                    <div class="btn-toolbar px-0 justify-content-end">
                                        <b-btn class="ml-3" href="javascript:window.print()" variant="primary" >{{$t('wallet.view.action.make_withdraw.item.button.print')}}</b-btn>
                                    </div>
                                </div>

                            </div>

                            <hr>

                            <div class="row px-5 pt-5 pb-0">
                                <div class="col-md-6">
                                    <p class="mb-1">Cannerald AG</p>
                                    <p class="mb-1">Kirchgasse 30</p>
                                    <p class="mb-1">3312 Fraubrunnen, BE</p>
                                    <p class="mb-1">{{$t('wallet.view.action.make_withdraw.item.payment.uid')}}: CHE-242.603.205</p>
                                </div>

                                <div class="col-md-6 text-right">
                                    <p class="font-weight-bold mb-4">{{$t('wallet.view.action.make_withdraw.item.details')}}</p>


                                    <p v-if="withdraw.type === 'bitcoin'">
                                        Bitcoin Address: <br/>{{ withdraw.crypto_address }}
                                    </p>

                                    <p v-if="withdraw.type === 'bank_transfer'">
                                        {{ withdraw.bank_account.iban }}<br/>
                                        {{ withdraw.bank_account.bank_name }}<br/>
                                        {{ withdraw.bank_account.bic }}<br/>
                                    </p>

                                </div>
                            </div>

                            <div class="row pb-5 p-5">
                                <div class="col-md-6">
                                    <p class="font-weight-bold mb-4">{{$t('wallet.view.action.make_withdraw.item.client_information')}}</p>
                                    <p class="mb-1" v-if="withdraw.address.type === 'company'">{{ withdraw.address.company }}</p>
                                    <p class="mb-1">{{ withdraw.address.firstname }} {{ withdraw.address.lastname }}</p>
                                    <p class="mb-1">{{ withdraw.address.street }} {{ withdraw.address.house_number }}</p>
                                    <p class="mb-1">{{ withdraw.address.zip }} {{ withdraw.address.town }}, {{ withdraw.address.country }}</p>
                                    <p class="mb-1" v-if="withdraw.address.type === 'company'">{{$t('wallet.view.action.make_withdraw.item.payment.uid')}}: {{withdraw.address.ustid}}</p>
                                </div>

                                <div class="col-md-6 text-right">

                                </div>

                            </div>

                            <div class="row p-5">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.action.make_withdraw.item.table.head.label')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.action.make_withdraw.item.table.head.item')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold">{{$t('wallet.view.action.make_withdraw.item.table.head.type')}}</th>
                                                <th class="border-0 text-uppercase small font-weight-bold text-right">{{$t('wallet.view.action.make_withdraw.item.table.head.amount')}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{{withdraw.transaction.label}}</td>
                                                <td>{{$t('wallet.view.action.make_withdraw.item.description')}}</td>
                                                <td>{{$t('wallet.view.action.make_withdraw.item.type.' + withdraw.type)}}</td>
                                                <td class="text-right">{{withdraw.amount}} EUR</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>0% {{$t('wallet.view.action.make_withdraw.item.tax')}}</td>
                                                <td class="text-right">{{ withdraw.amount | currency }} EUR</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row-reverse bg-secondary text-white p-4">
                                <div class="py-3 px-5 text-right">
                                    <div class="mb-2">Total</div>
                                    <div class="h2 font-weight-light mb-0">{{withdraw.amount | currency}} EUR</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>
    export default {
        data() {
            return {
                withdraw: {
                    transaction: {
                        label: '',
                        amount: '',
                        currency: {
                            shorthand: ''
                        }
                    },
                    address: {

                    },
                    amount: '',
                    type: '',
                    created_at: ''
                },
            }
        },
        mounted() {
            this.$api.get('wallet/withdraw/item/' + this.$route.params.withdraw_label).then(response => {
                this.withdraw = response.data;
            });
        },
        methods: {}
    }
</script>
